import React, { useState, useEffect } from "react";
import './meta.css'

function Meta() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className='meta-container'>
      <div className='max'>

        <div className='meta-header'>
          <div className='meta-inner'>
            <img src="/assets/Group 109-1.png" alt="로고"/>
            <div className='meta-head-r'>
              <span>
                <h3>Metastation</h3>
              </span>
            </div>
          </div>
        </div>

        <div className='meta-title-content'>
          <div className='meta-title-box'>
            <div className='meta-title'>
              <h1>Metastation</h1>
              <div className='boxboar'>
                <div className="box"></div>
              </div>
              {isSmallScreen ? (
              <>
                <div className="m-meta-text">
                  메타스테이션은 포용과 융합, 실험의 정신으로 예술과 삶이 <br />
                  융합된 활동을 통하여 사회적인 가치를 디지털 <br />
                  예술활동으로 추구하고 공공의 이익을 <br />
                  도모하고자 하여 설립한 비영리 예술단체입니다.
                </div>
              </>
            ) : (
              <p>
              메타스테이션은 포용과 융합, 실험의 정신으로 예술과 삶이 융합된 활동을 통하여 사회적인 가치를 디지털<br/>
              예술활동으로 추구하고 공공의 이익을 도모하고자 하여 설립한 비영리 예술단체입니다.
              </p>
            )}
            </div>
          </div>
        </div>

        <div className='meta-img-content'>
          <div className='meta-img-box'>
            <img src="/assets/Group 143.png" alt="로고"/>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Meta