import React, { useState, useEffect } from 'react'
import './kview.css'

function Kview() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className='kview-container'>
      <div className='title-content'>
        <div className='titlebox'>
          <div className='title'> 
            <h1>21세기를 향한 메타프로젝트</h1>
            <h1 className='cy'>‘나로부터 시작되는 우리’</h1>
          </div>
        </div>

        <div className='subtitlebox'>
          <div className='subtitle'>
          {isSmallScreen ? (
            <>
              {/* 모바일 버전 */}
              <div className='m-text'>
                기술이 발전하면서 예술세계도 확장되고 있고 또 다른 가능성의
                도전이 <br />
                가능해지고 있다. 특히 탈중앙화가 가능하고 다양한 실험이
                이루어지고
                <br />
                있는 메타버스에서의 예술은 여러 가지 장점을 가질 수 있다. <br />
                예술활동을 확장하면서 좋은 사회적 인식을 세계인들이 함께 <br />
                나누기 위해서는 메타버스라는 세계는 아주 가능성이 많은 공간이다.{" "}
                <br />
                그러기에 우리는 좋은 예술이 가지는 현대성과 사회성을 담보한
                장르의
                <br />
                통합을 이루어 내고자 한다. 또한 인터렉티브 작품을 통하여
                예술가와
                <br />
                비예술가의 경계를 허물며 누구나 예술행위를 가능하게 하는 동기를{" "}
                <br />
                부여하고자 한다. 이 모든 계획은 국가와 인종, 시공간을 넘나들면서<br />
                이루어지기에 
                기술이 예술의 발전을 이끌고, 예술이 기술에 기여하는<br /> 프로젝트가
                될 것이라기대되어 진다. 그리고 이 프로젝트를 통하여<br />
                기술과 예술의 융합으로
                세계인이 하나라는 인식 안에서 평화, 환경, 인권 등을
                <br /> 바라보게 되는 특별한 경험이 될 것이다.
              </div>
            </>
          ) : (
            <p>
              기술이 발전하면서 예술세계도 확장되고 있고 또 다른 가능성의 도전이 가능해지고 있다. 
              특히 탈중앙화가 가능하고 다양한 실험이 이루어지고 있는 메타버스에서의<br/> 
              예술은 여러 가지 장점을 가질 수 있다. 
              예술활동을 확장하면서 좋은 사회적 인식을 세계인들이 함께 나누기 위해서는 메타버스라는 세계는 아주 가능성이 많은 공간이다.<br/> 
              그러기에 우리는 좋은 예술이 가지는 현대성과 사회성을 담보한 장르의 통합을 이루어 내고자 한다. 
              또한 인터렉티브 작품을 통하여 예술가와 비예술가의 경계를 허물며 누구나 예술행위를 <br/>
              가능하게 하는 동기를 부여하고자 한다. 
              이 모든 계획은 국가와 인종, 시공간을 넘나들면서 이루어지기에 기술이 예술의 발전을 이끌고, 예술이 기술에 기여하는 프로젝트가 될 것이라 기대되어 진다.<br/> 
              그리고 이 프로젝트를 통하여 기술과 예술의 융합으로 세계인이 하나라는 인식 안에서 평화, 환경, 인권 등을 바라보게 되는 특별한 경험이 될 것이다.
            </p>
            )}
          </div>
        </div>

        <div className='kview-content'>
          <div className='kview-img-box'>
            <img src="/assets/Group 131.png" alt=""/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Kview