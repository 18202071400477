import React from 'react'
import './head.css'
import { NavLink } from 'react-router-dom'

function Header() {
  return (
    <header className='header'>
      <div className='header-content'>
        <div className='header-inner'>
          <div className='header-logo'>
            <NavLink to="/">
              <img src="/assets/Group 109-1.png" alt="로고"/>
            </NavLink>
          </div>
        </div>

        <div className='title-wrap'>
          <div className='title-wrapper'>
            <div className='titleimg'>
              <img src="/assets/Group 142.png" alt="메타스테이션"/>
            </div>
          </div>
        </div>

        <div className='subtitle-wrapper'>
          <div className='subtitle-text-section'>
            <div className='subtitle-text'>
              <h2>Institute of Meta<br/>Convergence Art</h2>
              <h2 className='pt'>Meta-project for the 21st century,<br/>'Us Starting from Me'</h2>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header