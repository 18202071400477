import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
const About = () => {
  const navigate = useNavigate();
  const [aboutContent] = useState({
    title: '디지털 낭만 전시회',
    description: '디지털 낭만은 현대 기술 시대에서 생명의 본질과 가치를 재발견하고자 하는 예술적 시도입니다. AI와 디지털 기술을 통해 모든 생명체가 평등한 세계를 그리며, 인간 중심적 사고를 넘어 생명과 평화, 환경의 가치를 예술로 구현합니다. 메타스테이션이 준비한 특별한 디지털 전시회에서 새로운 예술의 세계를 경험해보세요.',
    date: '2024.12.20 - 2025.01.31',
    location: '대구 예술 발전소 5층 복도',
  });

  return (
    
    <div className="min-h-screen bg-gradient-to-br from-purple-500 via-blue-500 to-indigo-600 p-8">
              <div className="flex justify-center mb-[20px]">
        <img 
          src="/assets/Group 109-1.png" 
          alt="META Logo" 
          className="h-12"
        />
      </div>
      <div className="max-w-4xl mx-auto bg-black rounded-2xl shadow-xl overflow-hidden">
        {/* 상단 네비게이션 */}
        <div className="bg-gray-100 p-4 flex justify-between items-center">
          <button 
            onClick={() => navigate('/exhibition')}
            className="text-gray-600 hover:text-white"
          >
            ← 돌아가기
          </button>
        </div>

        <div className="flex flex-col md:flex-row">
          {/* 포스터 영역 */}
          <div className="md:w-1/2 p-6">
            <div className="aspect-[3/4] bg-gray-200 rounded-lg overflow-hidden">
              <img 
                src="/assets/exi.jpg" 
                alt="전시회 포스터" 
                className="w-full h-full object-cover"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/assets/meta-favicon.png';
                }}
              />
            </div>
          </div>

          {/* 내용 영역 */}
          <div className="md:w-1/2 p-6">
            <div className="space-y-6">
              <h1 className="text-3xl font-bold text-white custom-font">
                {aboutContent.title}
              </h1>
              <div>
                <p className="text-gray-400 text-sm custom-font">전시회 소개</p>
                <p className="text-white leading-relaxed custom-font mt-1">
                  {aboutContent.description}
                </p>
              </div>
              <div className="space-y-4">
                <div>
                  <p className="text-gray-400 text-sm custom-font">일정</p>
                  <p className="text-white custom-font mt-1">{aboutContent.date}</p>
                </div>
                <div>
                  <p className="text-gray-400 text-sm custom-font">장소</p>
                  <p className="text-white custom-font mt-1">{aboutContent.location}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About; 