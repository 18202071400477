import React, { useState } from 'react';
import './Convergence.css';
import { FaArrowRightLong } from "react-icons/fa6";

function Convergence() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <section className='convergence'>
      <div className='img__box'>
        <img 
          src={`${process.env.PUBLIC_URL}/assets/Group 135.png`} 
          className='group135__img'
        />
      </div>

      <div className='convergence__container'>
        <div className='convergence__inner'>
          <div className='hall__move--container'>
            <div className='hall-move--inner'>
              <div 
                className='hall-move' 
                onMouseEnter={handleMouseEnter} 
                onMouseLeave={handleMouseLeave}
              >
                <span className='hall-move--txt'>전시장 이동</span>
              </div>
              <div className='right--arrow'>
                <FaArrowRightLong className='FaArrowRight'/>
              </div>
            </div>
          </div>
          <div className='convergence__title'>
            <h3 className='convergence__txt'>
              메타스테이션 - 01. 기획전시 <br/>
              INSTITUTE OF META CONVERGENCE ART
            </h3>
            <div className='circle__location'>
              <div className='circle__flex'>
                <div className='circle yellow'></div>
                <div className='circle white'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Convergence;
