import React from 'react'
import Header from '../component/Head/Header'
import Institute from '../component/Institute/Institute'
// import Art from '../component/Art/Art'
import Kview from '../component/Kview/Kview'
import Eview from '../component/Eview/Eview'
import Meta from '../component/Meta/Meta'
import Footer from '../component/Footer/Footer'
import Convergence from '../component/Convergence/Convergence'

function Home() {
  return (
    <>
      <Header/>
      <Institute/>
      <Convergence />
      {/* <Art/> */}
      <Kview/>
      <Eview/>
      <Meta/>
      <Footer/>
    </>
  )
}

export default Home