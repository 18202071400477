import React, { useState, useEffect } from "react";
import './footer.css'
import { NavLink } from "react-router-dom";

function Footer() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    handleResize(); // 초기 로드 시에도 크기를 확인

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer className='footer'>
      <div className='footer-content'>
        <div className='footer-center'>
          <img src="/assets/Group 109.png" alt="로고"/>

          <div className="com">
            <div className="com-text">
                {isSmallScreen ? (
                <>
                  <div className="m-footer">
                    대표자 : 김향금 | 사업자번호 : 109-82-85428 <br />
                    사업자 정보 확인 이메일 : meta-station@naver.com <br />
                    주소 : meta-station.com <br />
                    메타스테이션 메타융합예술연구소 | 개인정보처리방침 | 약관조회
                  </div>
                </>
              ) : (
                <div>
                  대표자 : 김향금 | 사업자번호 : 109-82-85428 | 사업자 정보 확인
                  이메일 : meta-station@naver.com | 주소 : meta-station.com <br />
                  메타스테이션 메타융합예술연구소 | 개인정보처리방침 | 약관조회
                </div>
              )}
            </div>
          </div>

        <div className="iconbox">
          <div className="f-icon-content">
            <div className="f-icons">
              <NavLink to="https://discord.gg/meta-station" target="_blank">
                <img src="/assets/Group 113.png" alt="디스코드" className="dis"/>
              </NavLink>
              <NavLink to="https://www.youtube.com/channel/UCi0_9Rkln8W2EkbyEPhGifQ" target="_blank">
              <svg
                width="72.96"
                height="49.92"
                viewBox="0 0 114 78"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="youtube"
              >
                <rect width="114" height="78" rx="20" fill="#E80000" />
                <path
                  d="M47.4545 24.5L75.9545 40.9545L47.4545 57.409L47.4545 24.5Z"
                  fill="#D9D9D9"
                />
              </svg>
              </NavLink>
              <NavLink to="https://www.instagram.com/meta_station_21/" target="_blank">
                <img
                  src="https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https:%2F%2Ft1.daumcdn.net%2Fcfile%2Ftistory%2F99B6AB485D09F2132A"
                  alt="Insta"
                  className="Insta"
                />
              </NavLink>
            <NavLink to="https://www.facebook.com/metastationlab" target="_blank" >
            <img src="/assets/Group 114.png" alt="" className="in"/>
            </NavLink>
            </div>
          </div>
          </div>

          <div className="footer-bottom">
            <div className="footer-b-head">
              <NavLink>
                <h3>메타융합예술연구소</h3>
              </NavLink>
              <div className="footer-r">
                <h3 className="r-txt">&lt; / MATASTATION / &gt;</h3>
              </div>
            </div>
          </div>

        </div>
      </div>
    </footer>
  )
}

export default Footer