import React, { useState, useEffect } from 'react'
import './eview.css'

function Eview() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className='eview-container'>
      <div className='title-content'>

        <div className='titlebox e-titlebox'>
          <div className='title e-title'> 
            <h1>
              META-PROJECT FOR THE<br/>
              21ST CENTURY, 'US STARTING FROM ME'
            </h1>
          </div>
        </div>

        <div className='e-text'>
          <div className='gab'>
            <div className='layout-l'>
            {isSmallScreen ? (
              <>
                <div className='m-etxt'>
                  As technology develops, the art field rapidly expands, and
                  thus it is <br />
                  possible to challenge another possibility. A variety of
                  artistic experiments <br /> are conducted in metaverse where
                  art itself can be decentralized in <br /> advantageous
                  modes. Metaverse is a space where people around the
                  <br /> world share social values while expanding artistic
                  boundaries. Indeed,
                  <br />
                  there are new artistic possibilities in metaverse.
                  Therefore, we try to <br /> achieve integration of various
                  genres in terms of arts reflecting <br /> contemporary ideas
                  and social values. Through interactive works,
                  <br /> we wish to ewbrace artists and non-artists in
                  metaverse space. <br /> All these projects interplay across
                  nations races, time and space. <br /> It is expected that
                  technology inspires art and art contributes to <br />
                  technology in good terms through the projects. This project
                  is <br /> the convergence of technology and art by which art
                  enables <br />
                  people around the world to experience peace, <br />
                  environments and human rights.
                </div>
                <div className='bottom-img'>
                  <div>
                    <img src="/assets/Group 131.png" alt=""/>
                  </div>
                </div>
              </>
              ) : (
                <div className='width'>
                <p className='stxt'>
                  As technology develops, the art field rapidly expands, and thus it is 
                  possible to <br/>challenge another possibility. A variety of artistic experiments
                  are conducted in <br/>metaverse where art itself can be decentralized in 
                  advantageous modes. <br/>Metaverse is a space where people around the 
                  world share social values while expanding <br/>artistic boundaries. Indeed, 
                  there are new artistic possibilities in metaverse. <br/>Therefore, we try to 
                  achieve integration of various genres in terms of arts <br/>reflecting contemporarys
                  ideas and social values. Through interactive works,<br/>we wish to ewbrace artists 
                  and non-artists in metaverse space. All these projects <br/>interplay across nations 
                  races, time and space. It is expected that technology inspires <br/>art and art
                  contributes to technology in good terms through the projects. This project is <br/>
                  the convergence of technology and art by which art enables people around the <br/>
                  world to experience peace, environments and human rights.
                </p>
                </div>
              )}
            </div>

            <div className='layout-r'>
              <div>
                <img src="/assets/Group 135.png" alt=""/>
              </div>
            </div>

           

          </div>
        </div>
      </div>
    </section>
  )
}

export default Eview