import React from 'react'
import './ins.css'
import { NavLink } from 'react-router-dom'

function Institute() {
  return (
    <section className='ins-container'>
      <div className='margin'>

        <div className='ins-header'>
          <div className='ins-inner'>
            <NavLink to='/' >
              <img src="/assets/Group 109-2.png" alt="로고"/>
            </NavLink>
            <div className='ins-head-r'>
              <span>
                <h3>메타스테이션 메타융합예술연구소</h3>
              </span>
            </div>
          </div>
        </div>

          <div className='main-info'>
            <div className='main-info-img'>
              <div className='info-box'>
                <img src="/assets/Group 122.png" alt="로고" className='bottom'/>
                <img src="/assets/Group 121.png" alt="로고"/>
                <img src="/assets/Group 120.png" alt="로고" className='top'/>
              </div>
            </div>
          </div>

        <div className='main-bottom'>
          <div className='main-bottom-text'>
            <p>INSTITUTE<br/> OF META CONVERGENCE ART</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Institute