import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import artworks from '../data/Data';

const ArtworkImage = ({ images, title, artwork }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [audioElement, setAudioElement] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { artworkId } = useParams();
  console.log('artworkId:', artworkId);
  
  useEffect(() => {
    setShowWelcome(true);
    setTimeout(() => setShowWelcome(false), 3000);

    const audio = new Audio(artwork.wav);
    setAudioElement(audio);
    setIsPlaying(true);

    audio.play().catch(error => {
      console.error('오디오 재생 중 오류:', error);
    });

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [artwork.wav]);

  const toggleAudio = () => {
    if (audioElement) {
      if (isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const nextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === images.length - 1 ? 0 : prev + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? images.length - 1 : prev - 1
    );
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream, { mimeType: 'audio/webm' }); // 지원되는 MIME 타입 사용

      recorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          audioChunksRef.current.push(e.data);
          console.log('Audio chunk received:', e.data.size);
        }
      };

      recorder.onstop = async () => {
        try {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
          console.log('Audio Blob size:', audioBlob.size);
          if (audioBlob.size === 0) {
            console.error('오디오 Blob이 비어있습니다.');
            setIsProcessing(false);
            return;
          }

          const formData = new FormData();
          formData.append('audio', audioBlob, 'question.webm'); // 올바른 파일명 및 확장자 사용
          formData.append('artwork_data', JSON.stringify(artwork));

          const response = await fetch('http://localhost:5000/api/answer-question', {
            method: 'POST',
            body: formData
          });

          const data = await response.json();
          
          if (data.success) {
            const audio = new Audio(`http://localhost:5000${data.audio_path}`);
            audio.onended = () => {
              setIsProcessing(false);
            };
            audio.play();
          } else {
            console.error('응답 오류:', data.error);
            setIsProcessing(false);
          }
        } catch (error) {
          console.error('서버 요청 오류:', error);
          setIsProcessing(false);
        }
        
        // 녹음 데이터 초기화
        audioChunksRef.current = [];
        mediaRecorderRef.current = null;
      };

      mediaRecorderRef.current = recorder;
      recorder.start();
      setIsRecording(true);
      
    } catch (error) {
      console.error('마이크 접근 오류:', error);
      alert('마이크 접근 권한이 필요합니다.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsProcessing(true);
    }
  };

  return (
    <div className="relative">
      <div className="relative pt-10 group bg-black" style={{ height: '500px' }}>
        <img 
          src={images[currentImageIndex]} 
          alt={title}
          className="w-full h-full object-contain"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/assets/meta-favicon.png';
          }}
        />
        
        {images.length > 1 && (
          <>
            <button 
              onClick={prevImage}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 
                bg-black/50 text-white p-2 rounded-full 
                opacity-0 group-hover:opacity-100 transition-opacity z-999"
            >
              ←
            </button>
            <button 
              onClick={nextImage}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 
                bg-black/50 text-white p-2 rounded-full 
                opacity-0 group-hover:opacity-100 transition-opacity z-999"
            >
              →
            </button>
          </>
        )}
      </div>

      <img 
        src="/assets/character.png" 
        alt="AI Docent" 
        onClick={() => setIsMinimized(!isMinimized)}
        className={`cursor-pointer transition-all duration-300
          ${isMinimized 
            ? 'w-[100px] fixed bottom-20 right-8 z-50' 
            : 'w-[500px] floating absolute top-0 left-1/2 -translate-x-1/2 m-4'}
          ${isRecording ? 'animate-pulse' : ''}`}
      />
      
      <div className="fixed bottom-8 right-8 z-50">
        <button
          onClick={() => isRecording ? stopRecording() : startRecording()}
          className={`bg-white rounded-full p-4 shadow-lg hover:scale-110 transition-transform
            ${isRecording ? 'bg-red-500' : 'bg-white'}`}
        >
         AI 도슨트에게 질문하기
        </button>
      </div>

      {showWelcome && (
        <div className="absolute top-4 left-4 bg-white/90 text-gray-800 px-4 py-2 rounded-lg shadow-lg">
          궁금한 점이 있으시면 편하게 말씀해 주세요!
        </div>
      )}
      {isRecording && (
        <div className="absolute top-4 right-40 bg-red-500 text-white px-3 py-1 rounded-full">
          녹음중...
        </div>
      )}
      {isProcessing && (
        <div className="absolute top-4 right-40 bg-blue-500 text-white px-3 py-1 rounded-full">
          처리중...
        </div>
      )}

      {audioElement && (
        <button
          onClick={toggleAudio}
          className="fixed top-4 right-4 bg-white/90 text-gray-800 px-4 py-2 rounded-lg shadow-lg z-50"
        >
          {isPlaying ? '그만듣기' : '다시듣기'}
        </button>
      )}
    </div>
  );
};

const ArtworkInfo = ({ artwork }) => (
  <div className="p-8 bg-black text-white">
    <div className="border-b border-gray-700 pb-4 mb-6">
      <h1 className="text-4xl font-bold mb-3 custom-font">{artwork.title}</h1>
      <p className="text-2xl custom-font">{artwork.artist}</p>
    </div>
    
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="space-y-4">
        <h2 className="text-xl font-semibold custom-font">작품 설명</h2>
        <p className="leading-relaxed whitespace-pre-line custom-font">
          {artwork.description}
        </p>
      </div>
      
      <div className="bg-black border border-gray-700 p-6 rounded-lg">
        <h2 className="text-xl font-semibold mb-4 custom-font">작품 정보</h2>
        <dl className="space-y-2">
          <div className="flex justify-between">
            <dt className="custom-font">제작 연도</dt>
            <dd className="custom-font">{artwork.year}</dd>
          </div>
          <div className="flex justify-between">
            <dt className="custom-font">매체</dt>
            <dd className="custom-font">{artwork.medium}</dd>
          </div>
          <div className="flex justify-between">
            <dt className="custom-font">주제</dt>
            <dd className="custom-font">{artwork.theme}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
);

const ArtworkDetail = () => {
  const { artworkId } = useParams();
  const navigate = useNavigate();
  const artwork = artworks[artworkId];

  if (!artwork) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-500 via-blue-500 to-indigo-600 py-8 px-4">
        <div className="max-w-6xl mx-auto">
          <div className="bg-white rounded-2xl shadow-xl p-8">
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-500 via-blue-500 to-indigo-600 py-8 px-4">
      <div className="flex justify-center mb-[20px]">
        <img 
          src="/assets/Group 109-1.png" 
          alt="META Logo" 
          className="h-12"
        />
      </div>
      <div className="max-w-6xl mx-auto">
        <button 
          onClick={() => navigate('/docent')}
          className="flex items-center text-white mb-6 hover:text-gray-200 transition-colors"
        >
          <span className="mr-2">←</span> 돌아가기
        </button>

        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <ArtworkImage images={artwork.images} title={artwork.title} artwork={artwork} />
          <ArtworkInfo artwork={artwork} />
        </div>
      </div>
    </div>
  );
};

export default ArtworkDetail;
